@mixin LogsTable {
  $MAX_CHAR_LEN: 100;

  .LogsRow {
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: #{$MAX_CHAR_LEN}ch;
    }

    .confirmation-url {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 450px;
    }

    .error {
      word-wrap: break-word;
      white-space: normal;
      max-width: 150px;
    }
  }
}

@mixin LogDetailsPopup {
  .label {
    font-size: 0.8rem;
    font-weight: 500;
    color: theme-color("gray-600");
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  .LogDetailsTable, 
  .LogDetailsResultsTable {
    @include LogsTable;
  }
}

.CronLogsTable {
  .LogsTable {
    @include LogsTable;
  }

  .LogDetailsPopup {
    @include LogDetailsPopup;
  }
}
