@import "styles/theme-colors";

@mixin ReservationsSearch {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;

  label {
    color: theme-color("gray-700");
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  input {
    height: calc(1em + 0.75rem + 2px);

    &:focus {
      box-shadow: none;
    }
  }
}

@mixin ReservationsTable {
  $MAX_CHAR_LEN: 60;

  .ReservationRow {
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: #{$MAX_CHAR_LEN}ch;
    }

    .restaurant-link,
    .screenshot-link,
    .confirmation-url {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 450px;
    }

    .error {
      word-wrap: break-word;
      white-space: normal;
      max-width: 150px;
    }
  }
}

.Reservations {
  .ReservationsTable {
    @include ReservationsTable;
  }

  .RestaurantsDetailsTable {
    @include ReservationsTable;
  }

  .ScreenshotsDetailsTable {
    @include ReservationsTable;
  }

  .ReservationsSearch {
    @include ReservationsSearch;
  }
}

.image-preview {
  object-fit: contain;
  overflow: hidden;
}
