@import 'styles/theme-colors';

.line-item-wrapper {
	justify-content: space-between;

	&:not(:first-child) {
		margin-top: .75rem;
	}

	.item-wrapper {
		flex-direction: column;

		&:nth-child(even) {
			text-align: right;
		}

		.label {
			font-size: 0.75rem;
			font-weight: 500;
			color: theme-color("gray-600");
			text-transform: uppercase;
		}

		.value {
			font-size: 0.95rem;
			font-weight: 500;
		}
	}
}
