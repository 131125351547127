@mixin EventsSearch {
	font-size: .9rem;
	height: calc(1.25em + 0.75rem + 2px);

	&:focus {
		box-shadow: none;
	}
}

@mixin EventsTable {
	$MAX_CHAR_LEN: 60;

	.EventRow {
		td {
			white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    max-width: #{$MAX_CHAR_LEN}ch;
		}
	}
}

.TourEventsTable {
	.EventsTable {
		@include EventsTable;
	}

	.EventsSearch {
		@include EventsSearch;
	}
}
