@mixin EventPackagesTable {
	$MAX_CHAR_LEN: 60;

	.EventRow {
		td {
			white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    max-width: #{$MAX_CHAR_LEN}ch;
		}
	}
}

.TourEventPackagesTable {
	.EventPackagesTable {
		@include EventPackagesTable;
	}
}
