@mixin TableDefault {
	table {
		$MAX_CHAR_LEN: 60;

		thead {
			tr {
				th {
					border-top: none;
				}
			}
		}

		tbody {
			tr {
				td {
					white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    max-width: #{$MAX_CHAR_LEN}ch;
				}
			}
		}
	}
};

@mixin MktTix {
	@include TableDefault;
}

@mixin TicketPriceHistory {
	@include TableDefault;
}

@mixin SuggestedPriceHistory {
	@include TableDefault;
}

.EventPackagePopup {
	.MktTix {
		@include MktTix;
	}

	.TicketPriceHistory {
		@include TicketPriceHistory;
	}

	.SuggestedPriceHistory {
		@include SuggestedPriceHistory;
	}
}
