@mixin ToursSearch {
	font-size: .9rem;
	height: calc(1.25em + 0.75rem + 2px);

	&:focus {
		box-shadow: none;
	}
}

@mixin ToursTableTable {
	$MAX_CHAR_LEN: 60;

	.TourRow {
		td {
			white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    max-width: #{$MAX_CHAR_LEN}ch;
		}
	}
}

.ToursTable {
	.ToursTableTable {
		@include ToursTableTable;
	}

	.ToursSearch {
		@include ToursSearch;
	}
}
