.ticketnetwork {
  color: #1673E6;
}

.mercury {
  color: #048D00;
}

.ticketevolution {
  color: #00BCD4;
}

.seatgeek {
  color: #3A97D4;
}

.ticketmaster {
  color: #2CA8FF;
}

.ticketmaster-resale {
  color: #D0006F;
}

.vivid {
  color: #8B49B3;
}
