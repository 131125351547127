@mixin StatusOptions {

}

@mixin CronsTable {
	table {
		thead {
			tr {
				th {
					border-top: none;
				}
			}
		}

		tbody {
			tr {
				td {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
}

.Crons {
	.StatusOptions {
		@include StatusOptions;
	}

	.CronsTable {
		@include CronsTable;
	}
}
