@import 'styles/theme-colors';

@mixin PaginationBtn {
	padding: .2rem .75rem;
	border: 1px solid theme-color("gray-300");
	border-radius: .25rem;
	background-color: theme-color("light");
	color: theme-color("gray-500");
	cursor: pointer;
	transition: background-color 0.15s ease, color 0.15s ease;
	user-select: none;

	&:not(:first-child) {
		margin-left: .25rem;
	}

	&:hover {
		background-color: white;
	}

	&.active {
		background-color: white;
		color: black;
	}
}

.Pagination {
	overflow-x: scroll;

	.PaginationBtn {
		@include PaginationBtn;
	}
}
