@mixin card-header {
	padding: 0.35rem .75rem;
}

@mixin card-body {

}

@mixin card-footer {
	padding: 0.35rem .75rem;
}

.card {
	.card-header {
		@include card-header;
	}

	.card-body {
		@include card-body;
	}

	.card-footer {
		@include card-footer;
	}
}
